.home {
  .top-block {
    width: 60%;
    height: auto;
    margin: 0 auto;
    background: ghostwhite;
    padding: 3rem 2rem 5rem 2rem;

    .options {
      display: flex;
      justify-content: space-between;
      font-size: 0.8em;
      .left {
        display: flex;
        .item {
          cursor: pointer;
          margin: 0 0.3rem;
        }
      }
      .right {
      }
    }
    .input-box {
      display: flex;
      input {
        margin-top: 0.3rem;
        padding-right: 4rem;
      }
      .add-button {
        cursor: pointer;
        margin-left: -2.7rem;
        margin-top: 0.3rem;
        img {
          width: 2.7rem;
        }
      }
    }
    .list {
      margin-top: 2rem;
      .item {
        .check-box {
          img {
            width: 1.5rem;
          }
        }
        display: flex;
        .value {
          width: 100%;
          padding: 1rem;
          margin-top: -1.4rem;
          .name {
            font-size: 1.4em;
            overflow-wrap: break-word;
          }
          .input-box {
            display: flex;
            button {
              margin-top: 0.3rem;
              margin-left: -0.6rem;
            }
          }
          .controls {
            display: flex;
            visibility: hidden;
            font-size: 0.8em;
            .separation {
              margin: 0 0.3rem;
            }
            .edit {
              cursor: pointer;
            }
            .delete {
              cursor: pointer;
            }
          }
          &:hover {
            background: rgb(240, 238, 238);
            .controls {
              visibility: visible;
            }
          }
        }
      }
    }
    a {
      color: black;
      &:hover {
        color: black;
      }
    }
    .help-section {
      .help {
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
      }
      .expanded {
      }
    }
  }
}
